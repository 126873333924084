import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class AskDCEndpoints extends BaseEndpointsClass {
    constructor(data) {
        super();
        this.resourceEndpoint = "/ask-dc";
        this.transformer = null;
    }
    addCollection(data) {
        return axios.post(`${this.resourceEndpoint}/collections`, data.apiData.data);
    }
    getCollections(data) {
        return axios.get(`${this.resourceEndpoint}/collections`);
    }
    getCollection(data) {
        return axios.get(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}`);
    }
    deleteCollection(data) {
        return axios.delete(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}`);
    }
    editCollection(data) {
        return axios.patch(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}`, data.apiData.data);
    }
    addUserToCollection(data) {
        return axios.post(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}/users`, data.apiData);
    }

    deleteUserFromCollection(data) {
        return axios.delete(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}/users/${data.apiData.userId}`);
    }
    getSourceType() {
        return axios.get(`${this.resourceEndpoint}/collection-source-types`);
    }
    addSource(data) {
        return axios.post(`${this.resourceEndpoint}/collection-sources`, data.apiData.data);
    }
    getSources() {
        return axios.get(`${this.resourceEndpoint}/collection-sources`);
    }
    getTableDescriptions(data) {
        return axios.post(`${this.resourceEndpoint}/suggestion/table-description`, data.apiData.data);
    }
    getGlossary(data) {
        return axios.post(`${this.resourceEndpoint}/suggestion/glossary`, data.apiData.data);
    }
    getTableRelations(data) {
        return axios.post(`${this.resourceEndpoint}/suggestion/table-relations`, data.apiData.data);
    }
    askQuery(data) {
        return axios.post(
            `${this.resourceEndpoint}/collections/${data.apiData.collectionId}/threads/${data.apiData.threadId}/question`,
            { query: data.apiData.query }
        );
    }
    createThread(data) {
        return axios.post(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}/threads`, {
            thread: { name: data.apiData.threadName },
        });
    }
    deleteThread(data) {
        return axios.delete(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}/threads/${data.apiData.threadId}`);
    }
    patchThread(data) {
        return axios.patch(`${this.resourceEndpoint}/collections/${data.apiData.collectionId}/threads/${data.apiData.threadId}`, {
            thread: { name: data.apiData.name },
        });
    }
    getTables(data) {
        return axios.get(`${this.resourceEndpoint}/collection-sources/${data.apiData.collectionId}/tables`);
    }
    getTableColumns(data) {
        return axios.post(
            `${this.resourceEndpoint}/collection-sources/${data.apiData.collectionId}/tables/columns`,
            data.apiData.data
        );
    }
}

export default AskDCEndpoints;
